import React, { useState } from "react";
import { useParams } from "react-router-dom";

import CaseImage from "../components/CaseImage";
import Price from "../components/Price";
import SectionHeading from "../components/SectionHeading";
import { Spinner, Showcase, SpinType, WinModal } from "../components/case";
import { CaseDetails, Prize, UserItem } from "../types";
import { useFetch, useAction } from "../utils/index";
import Button from "../components/button";
import Container from "../components/Container";
import Range from "../components/Range";

interface Opening {
  prize: Prize;
  item: UserItem;
}

const Opening = () => {
  const { id } = useParams<{ id: string }>();

  const [data] = useFetch<CaseDetails>(`/case/${id}`);

  const [count, setCount] = useState(1);
  const [spinning, setSpinning] = useState(false);
  const [spinType, setSpinType] = useState(SpinType.DEMO);
  const [showModal, setShowModal] = useState(false);

  const [openCase, opened] = useAction<Opening[]>(`/case/${id}`, {
    format(x) {
      return x.map(({ prize, prize_inv_id }: any) => {
        return { prize, item: { id: prize_inv_id, item: prize.item } };
      });
    },
    done() {
      setSpinning(() => {
        setSpinType(SpinType.NORMAL);
        return true;
      });
    },
  });

  return (
    <Container>
      <WinModal
        items={opened ? opened.map(({ item }) => item) : []}
        close={() => setShowModal(false)}
        show={showModal}
      />

      <div className="flex items-center w-full h-72 px-16 bg-gray-700 shadow-lg rounded">
        <CaseImage
          src={data?.case.image || ""}
          name={data?.case.name || ""}
          className="w-48 mr-16"
        />
        <div className="-mt-1.5">
          <div className="text-lg font-semibold text-gray-25">
            <span className="mr-1.5">{data?.case.fullname.toUpperCase()}</span>
            <span className="text-xl text-red">
              <Price value={data?.case.price || 0} />
            </span>
          </div>
          <Range
            optionClassName="w-10 h-10 mr-3"
            range={[1, 4]}
            count={count}
            onClick={setCount}
            disabled={spinning}
          />
          <div className="flex items-center text-gray-25">
            <Button
              onClick={() => openCase({ amount: count })}
              className={`flex justify-center items-center h-14 w-52 ml-auto mr-3 py-2 text-center bg-red-500 ${
                !spinning ? "shadow-red" : ""
              } text-sm font-semibold ${spinning ? "opacity-50" : ""}`}
              hoverEffect={!spinning}
              disabled={spinning}
            >
              {!spinning ? `OPEN ${count} CASE${count > 1 ? "S" : ""}` : "SPINNING..."}
            </Button>
            <Button
              onClick={(_) => setSpinning(true)}
              className={`h-12 py-2 px-6 bg-gray-500 ${
                !spinning ? "shadow-gray" : "opacity-50"
              } text-xs font-semibold`}
              disabled={spinning}
            >
              DEMO SPIN
            </Button>
          </div>
        </div>
      </div>

      <Spinner
        items={data?.prizes || []}
        targets={opened?.map((o: any) => o.prize)}
        count={count}
        type={spinType}
        spinning={spinning}
        done={(type) => {
          setSpinning(() => {
            type === SpinType.NORMAL && setShowModal(true);
            setSpinType(SpinType.DEMO);
            return false;
          });
        }}
      />

      <SectionHeading>ITEMS IN THIS CASE</SectionHeading>
      <Showcase items={data?.prizes.reverse() || []} />
    </Container>
  );
};

export default Opening;
