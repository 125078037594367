import React from "react";

const SectionHeading: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => (
  <div className="flex items-center h-8 p-3 font-semibold text-tiny text-gray-50">
    {children}
  </div>
);

export default SectionHeading;