enum Tier {
  COMMON = "common",
  UNCOMMON = "uncommon",
  RARE = "rare",
  EPIC = "epic",
  LEGENDARY = "legendary",
}

export { Tier };

interface User {
  steamid: string;
  username: string;
  avatar: string;
  balance: number;
  assets: number;
  tradeurl?: string;
}

interface Item {
  name: string;
  image: string;
  price: number;
}

interface UserItem {
  id: number;
  item: Item;
}

interface Case {
  id: number;
  name: string;
  fullname: string;
  image: string;
  price: number;
  section: string;
}

interface Prize {
  item: Item;
  low_ticket: number;
  high_ticket: number;
}

interface CaseDetails {
  case: Case;
  prizes: Prize[];
}

interface LivefeedCard {
  id: number;
  user: User;
  case: Case;
  item: Item;
}

interface BattlePlayer {
  slot: number;
  user: User;
}

interface BattleRound {
  round: number;
  case: Case;
}

interface Battle {
  id: number;
  cost: number;
  owner: string;
  capacity: number;
  state: number;
  players: User[];
  rounds: Case[];
  prizes: Item[][];
  beacon?: string;
  started_at?: string;
}

interface BattleDetails {
  details: Battle;
  cases: { [key: number]: Prize[] };
}

type ReactClickEvent = React.MouseEvent<HTMLButtonElement, MouseEvent>;

export type {
  User,
  Item,
  Case,
  Prize,
  CaseDetails,
  UserItem,
  LivefeedCard,
  Battle,
  BattlePlayer,
  BattleRound,
  BattleDetails,
  ReactClickEvent,
};
