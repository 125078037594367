import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { useStore } from "./store";
import { REACT_API_PATH, useFetch } from "./utils";
import { useChannel, CHANNELS } from "./socket";

import ScrollToTop from "./components/ScrollToTop";
import Navigation from "./components/navigation";
import Footer from "./components/Footer";
import Subscriber from "./components/Subscriber";

import Battles from "./pages/Battles";
import Battle from "./pages/Battle";
import Create from "./pages/Create";
import Cases from "./pages/Cases";
import Profile from "./pages/Profile";
import Opening from "./pages/Opening";

import { LivefeedCard, User } from "./types";
import { useEffect } from "react";
import { useCallback } from "react";
import axios from "axios";

function App() {
  const [me, authenticate, setBalance, livefeedInit] = useStore((state) => [
    state.me,
    state.authenticate,
    state.setBalance,
    state.livefeedInit,
    state.livefeedAdd,
  ]);

  useChannel<LivefeedCard>(CHANNELS.livefeed, {
    received(data) {
      // livefeedAdd(data);
    },
  });

  useChannel<{ balance: number }>(me && CHANNELS.balance(me.steamid), {
    received({ balance }) {
      setBalance(balance);
    },
  });

  useFetch<LivefeedCard[]>("/history/cases", {
    onResponse(feed) {
      livefeedInit(feed);
    },
  });

  useFetch<User>("/user", {
    onResponse(user) {
      authenticate(user);
    },
  });

  const handlemessage = useCallback(
    (event) => {
      if (event.data.success !== true) {
        return;
      }

      axios(REACT_API_PATH + "/user")
        .then((data) => {
          authenticate(data.data);
        })
        .catch((e) => console.error(e));
    },
    [authenticate]
  );

  useEffect(() => {
    window.addEventListener("message", handlemessage);
    return () => window.removeEventListener("message", handlemessage);
  }, [handlemessage]);

  return (
    <Router>
      <Subscriber />
      <Navigation me={me} />
      <main className="w-full min-h-screen">
        <div className="w-full h-20"></div>
        <ScrollToTop />
        <Switch>
          <Route path="/profile/:id">
            <Profile />
          </Route>
          <Route path="/case/:id">
            <Opening />
          </Route>
          <Route path="/battle/:id">
            <Battle />
          </Route>
          <Route path="/battles/create">
            <Create />
          </Route>
          <Route path="/battles">
            <Battles />
          </Route>
          <Route path="/">
            <Cases />
          </Route>
        </Switch>
      </main>
      <Footer />
    </Router>
  );
}

export default App;
