import React from "react";

import { ModalWithHeading } from "../Modal";
import { InventoryItem } from "../item";
import Price from "../Price";

import { UserItem } from "../../types";
import { useAction } from "../../utils";
import Button from "../button";

interface Sold {
  sold: number[];
}

const WinModal: React.FC<{
  items: UserItem[];
  show: boolean;
  close: () => void;
}> = ({ items, show, close }) => {
  const [sold, setSold] = React.useState<number[]>([]);

  const [sellItem] = useAction<Sold>("/user/inventory", {
    done(data) {
      data.sold.length + sold.length === items.length && close();
      setSold([...sold, ...data.sold]);
    },
  });

  React.useEffect(() => {
    setSold([]);
  }, [show]);

  return (
    <ModalWithHeading
      show={show}
      className="w-sm-modal mx-auto my-28 px-3 pb-7"
      heading={`UNBOXED ${items.length} ITEMS`}
      subheading="THE ITEMS HAVE BEEN ADDED TO YOUR INVENTORY"
    >
      <div className="flex items-center justify-center mb-7">
        {items.map((item, index) => (
          <InventoryItem
            key={index}
            item={item}
            className={`w-40 mx-1 ${sold.includes(item.id) ? "opacity-0" : ""} duration-150`}
            onSell={(id) => {
              sellItem({ sell: [id] });
            }}
          />
        ))}
      </div>
      <div className="flex items-center justify-center font-semibold text-xs text-gray-25">
        <Button
          color="red"
          className="h-10 mr-3 py-2 px-12"
          onClick={() => {
            sellItem({ sell: items.map(({ id }) => id) });
          }}
        >
          SELL ALL (
          <Price
            value={items.reduce<number>((p, c) => (sold.includes(c.id) ? p : p + c.item.price), 0)}
          />
          )
        </Button>
        <Button color="gray" onClick={() => close()} className="h-10 py-2 px-8">
          CONTINUE
        </Button>
      </div>
    </ModalWithHeading>
  );
};

export default WinModal;
