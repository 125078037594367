import React from "react";
import { NavLink } from "react-router-dom";

const GameLink: React.FC<{
  children: React.ReactNode;
  to: string;
  active?: boolean;
}> = ({ children, active = true, to }) => {
  const classes =
    "flex items-center px-4 h-full text-gray-50 font-semibold text-xs";

  return active ? (
    <NavLink
      to={to}
      exact={true}
      activeClassName="pt-px text-red border-b border-red-500"
      className={classes + " hover:text-red"}
      href="/"
    >
      {children}
    </NavLink>
  ) : (
    <div className={classes + " cursor-default"}>{children}</div>
  );
};

export default GameLink;
