import React, { useLayoutEffect, useMemo } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { useStore } from "../store";

import { Header } from "../components/battle";
import LiveFeed from "../components/livefeed";
import Button from "../components/button";
import Price from "../components/Price";
import CaseImage from "../components/CaseImage";
import Range from "../components/Range";

import { Style, useAction, useFetch } from "../utils";
import { useState } from "react";
import { Box, Value } from "../components/layout";
import { Modal } from "../components/Modal";

import { ReactComponent as LeftArrow } from "../icons/arrow-left.svg";
import { ReactComponent as Plus } from "../icons/plus.svg";

import { Case, ReactClickEvent } from "../types";

const AddCase: React.FC<{ onClick: () => void }> = ({ onClick }) => (
  <button
    onClick={onClick}
    className="flex flex-col justify-center items-center h-72 bg-gray-800 rounded text-gray-40"
  >
    <Plus className="w-8 h-8 fill-current" />
    <span className="font-semibold">ADD CASE</span>
  </button>
);

interface Compressed extends Case {
  count: number;
}

const Side: React.FC<{
  onClick?: (event: ReactClickEvent) => void;
  className?: string;
  children: React.ReactNode;
}> = ({ onClick, className, children }) => (
  <button
    onClick={onClick}
    className={Style(className, "flex items-center justify-center w-10 h-full").toString()}
  >
    {children}
  </button>
);

const CountInput: React.FC<{ count: number; onAction?: (change: number) => void }> = ({
  count,
  onAction,
}) => (
  <div className="flex items-center w-full h-10 text-gray-25 bg-gray-800 rounded shadow-inner">
    <Side onClick={() => onAction && onAction(-1)}>-</Side>
    <span className="flex justify-center w-full">{count}</span>
    <Side onClick={() => onAction && onAction(1)} className="ml-auto">
      +
    </Side>
  </div>
);

type OnRoundChange = (index: number, change: number) => void;
type OnRoundAdd = () => void;

const Round: React.FC<{
  onChange?: OnRoundChange;
  onAdd?: OnRoundAdd;
  index?: number;
  round: Case;
  count?: number;
}> = ({ onChange, onAdd, index, round, count }) => (
  <Box className="h-72" direction="vertical">
    <CaseImage className="w-40" name={round.name} src={round.image} />
    <Value label={round.fullname}>
      <Price value={round.price} />
    </Value>
    {index !== undefined && count ? (
      <CountInput count={count} onAction={(change) => onChange && onChange(index, change)} />
    ) : (
      <Button
        color="green"
        className="w-full h-10"
        onClick={() => {
          onAdd && onAdd();
        }}
      >
        ADD
      </Button>
    )}
  </Box>
);

const AddCaseModal: React.FC<{
  cost: number;
  length: number;
  show?: boolean;
  onAdd: (e: Case) => void;
  close: () => void;
}> = ({ cost, length, show, onAdd, close }) => {
  const [cases] = useFetch<Case[]>("/case");
  const [search, setSearch] = useState("");

  const filter = useMemo(
    () => cases?.filter((x) => x.fullname.toLowerCase().includes(search.toLowerCase().trim())),
    [cases, search]
  );

  return (
    <Modal className="flex justify-center items-center" show={show}>
      <div className="relative sm:max-w-19/20 max-w w-full sm:w-lg-modal h-screen sm:h-auto p-6 bg-gray-700 rounded">
        <input
          placeholder="Search Cases..."
          className="w-full mb-6 p-3 text-xs text-gray-40 bg-gray-900 shadow-inner rounded"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        ></input>
        <div className="relative grid gap-3 grid-cols-cases mb-6" style={{ minHeight: "200px" }}>
          {filter && filter.length ? (
            filter.map((round) => (
              <Round
                key={round.id}
                round={round}
                onAdd={() => {
                  onAdd(round);
                }}
              />
            ))
          ) : (
            <span className="absolute left-1/2 transform -translate-x-1/2 text-center text-gray-40">
              NO CASES FOUND
            </span>
          )}
        </div>
        <div className="flex items-center gap-6">
          <Value label="total value">
            <Price value={cost} />
          </Value>
          <Value label="cases">{length}</Value>
          <Button className="h-10 px-9 ml-auto" color="green" onClick={close}>
            DONE
          </Button>
        </div>
      </div>
    </Modal>
  );
};

const Rounds: React.FC<{
  rounds: Compressed[];
  onChange: OnRoundChange;
  openAddModal: () => void;
}> = ({ openAddModal, onChange, rounds }) => (
  <div className="grid gap-4 grid-cols-cases px-3 mt-6">
    <AddCase onClick={openAddModal} />
    {rounds.map((round, index) => (
      <Round key={index} index={index} round={round} count={round.count} onChange={onChange} />
    ))}
  </div>
);

const remove = (rounds: Compressed[], i: number, change: number) => {
  const indirect = [...rounds];

  if (rounds[i].count + change) {
    indirect[i] = { ...indirect[i], count: indirect[i].count + change };
    return indirect;
  }

  const previous = rounds[i - 1];
  const next = rounds[i + 1];

  if (previous && next && previous.id === next.id) {
    indirect[i - 1] = { ...previous, count: previous.count + next.count };
    indirect.splice(i, 2);
    return indirect;
  }

  indirect.splice(i, 1);
  return indirect;
};

interface CreateResponse {
  id: number;
}

const Create = () => {
  const [livefeed] = useStore((state) => [state.livefeed]);

  const [capacity, setCapacity] = useState(2);
  const [rounds, setRounds] = useState<Compressed[]>([]);
  const [enabled, setEnabled] = useState(false);

  const [price, unrolled] = useMemo(
    () => [
      rounds.reduce((accum, x) => accum + x.price * x.count, 0),
      rounds.reduce((accum, x) => accum + x.count, 0),
    ],
    [rounds]
  );

  const history = useHistory();
  const location = useLocation<Case[] | undefined>();

  useLayoutEffect(() => {
    if (!location.state) {
      return;
    }

    setRounds(location.state.map((x) => ({ ...x, count: 1 })));
  }, [location]);

  const [create, , loading] = useAction<CreateResponse>("/battle", {
    done(data) {
      history.push("/battle/" + data.id);
    },
  });

  return (
    <div className="w-full pb-10">
      <AddCaseModal
        cost={price}
        length={unrolled}
        onAdd={(x) => {
          setRounds((rounds) =>
            rounds[rounds.length - 1]?.id === x.id
              ? [
                  ...rounds.slice(0, rounds.length - 1),
                  { ...rounds[rounds.length - 1], count: rounds[rounds.length - 1].count + 1 },
                ]
              : [...rounds, { ...x, count: 1 }]
          );
        }}
        show={enabled}
        close={() => setEnabled(false)}
      />
      <LiveFeed cards={livefeed} />
      <Header
        title="CREATE BATTLE"
        left={
          <Link to="/battles" className="flex items-center justify-center text-gray-25">
            <LeftArrow className="fill-current" /> BACK
          </Link>
        }
        right={
          <Button
            color="green"
            disabled={loading || price === 0}
            className="w-64 h-12 ml-auto"
            onClick={() => {
              create({ capacity, rounds: rounds.map((x) => ({ id: x.id, count: x.count })) });
            }}
          >
            CREATE BATTLE FOR <Price value={price} />
          </Button>
        }
      />
      <div className="flex items-center h-20 mx-3 px-6 bg-gray-700 rounded">
        <Value className="mr-6" label="battle cost">
          {<Price value={price} />}
        </Value>
        <div className="flex items-center text-sm text-gray-25">
          <span className="mr-2">PLAYERS</span>
          <Range
            optionClassName="w-8 h-8 mr-2"
            range={[2, 4]}
            count={capacity}
            onClick={setCapacity}
            disabled={false}
          />
        </div>
        <Value className="ml-auto" label="TOTAL ROUNDS">
          {unrolled}
        </Value>
      </div>
      <Rounds
        openAddModal={() => setEnabled(true)}
        onChange={(i, change) => {
          setRounds((rounds) => remove(rounds, i, change));
        }}
        rounds={rounds}
      />
    </div>
  );
};

export default Create;
