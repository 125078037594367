import React from "react";

const MAX_TICKETS = 1000000;

/**
 * Displays the procentage chance to win based on a ticket range
 */
const Chance: React.FC<{ className?: string; tickets: [number, number] }> = ({
  className,
  tickets,
}) => (
  <span className={className}>
    {(((tickets[1] - tickets[0] + 1) / MAX_TICKETS) * 100).toFixed(2)}%
  </span>
);

export default Chance;
