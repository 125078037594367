import React from "react";

const ItemImage: React.FC<{
  name: string;
  image: string;
  size?: number;
  className?: string;
}> = ({ name, image, size = 200, className }) => {
  const src =
    "https://steamcommunity-a.akamaihd.net/economy/" +
    `image/${image}/${size}x${size}`;

  return <img src={src} alt={name} className={className} draggable="false" />;
};

export default ItemImage;
