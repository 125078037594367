import CaseImage from "../CaseImage";
import { Case } from "../../types";
import { Triangle } from "../layout";
import { animated, useSpring } from "react-spring";
import Skeleton from "react-loading-skeleton";
import { Style } from "../../utils";

const Round: React.FC<{ round: Case; active: boolean }> = ({ round, active }) => (
  <div className="flex justify-center items-center flex-col flex-shrink-0 w-22 h-22 my-1 ml-1 p-2 bg-gray-700 rounded">
    <CaseImage
      className={Style().if(!active, "filter grayscale").toString()}
      src={round.image}
      name={round.fullname}
    />
  </div>
);

const CurrentMarker: React.FC<{ current: number }> = ({ current }) => {
  const styles = useSpring({ translateX: (current + 0.5) * 92 - 4 });

  return (
    <animated.div className="absolute h-full" style={styles}>
      <Triangle h={10} w={12} className="absolute top-0" direction="bottom" color="#a6a7aa" />
      <Triangle h={10} w={12} className="absolute bottom-0" direction="top" color="#a6a7aa" />
    </animated.div>
  );
};

const RoundsSkeleton = () => (
  <div className="w-full h-24 leading-none">
    <Skeleton height={96} />
  </div>
);

const Rounds: React.FC<{ current?: number; rounds: Case[] }> = ({ current = -1, rounds }) => {
  return (
    <div className="relative flex w-full h-24 overflow-x-hidden shadow-inner rounded bg-gray-800">
      <CurrentMarker current={Math.min(current, rounds.length - 1)} />
      {rounds.map((round, index) => (
        <Round key={index} round={round} active={index >= current} />
      ))}
    </div>
  );
};

export { RoundsSkeleton };
export default Rounds;
