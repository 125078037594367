import React, { useMemo } from "react";
import { useParams } from "react-router-dom";
import Container from "../components/Container";
import { Style, useFetch } from "../utils";
import { Battle, BattleDetails, Item, Prize, User } from "../types";
import { JoinConfirmModal, Rounds, RoundsSkeleton, Spinner } from "../components/battle";
import Price from "../components/Price";
import Avatar from "../components/Avatar";
import { useState } from "react";
import { useEffect } from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { ItemImage } from "../components/item";
import { ModalWithHeading } from "../components/Modal";
import Button from "../components/button";
import { Value } from "../components/layout";
import { CHANNELS, useChannel } from "../socket";
import { progress } from "../components/battle/Spinner";

interface RoundPrizeProps {
  item?: Item;
  index: number;
}

const RoundPrize: React.FC<RoundPrizeProps> = ({ index, item }) => (
  <div
    className={Style(
      "flex items-center justify-center h-20 ",
      "text-gray-50 font-semibold text-2xl rounded"
    )
      .if(!!item, "bg-gray-500", "bg-gray-800")
      .toString()}
  >
    {item ? (
      <div className="flex items-center w-full h-full px-3 text-gray-25 text-sm">
        <ItemImage image={item.image} name={item.name} className="w-14 h-14 mr-3" />
        <div className="flex justify-center overflow-hidden flex-col">
          <span className="font-normal overflow-ellipsis overflow-hidden whitespace-nowrap">
            {item.name}
          </span>
          <span className="text-xs">
            <Price value={item.price} />
          </span>
        </div>
      </div>
    ) : (
      index + 1
    )}
  </div>
);

interface PlayerPrizesProps {
  slot: number;
  count: number;
  round: number;
  prizes: Item[][];
}

const PlayerPrizes: React.FC<PlayerPrizesProps> = ({ slot, count, round, prizes }) => {
  const sliced = prizes.slice(0, Math.max(0, round));

  return (
    <div className="grid grid-cols-prizes gap-3">
      {[...Array(count)].map((_, index) => (
        <RoundPrize
          key={index}
          index={index}
          item={sliced[index] ? sliced[index][slot] : undefined}
        />
      ))}
    </div>
  );
};

const PlayerDetails: React.FC<{ user?: User }> = ({ user }) => (
  <div className="flex items-center justify-center w-full p-4">
    <Avatar size={1} src={user?.avatar} name={user?.username} className="w-8 h-8 mr-2 rounded" />
    <span className="text-xs font-semibold text-gray-25">
      {user?.username || "Waiting for player..."}
    </span>
  </div>
);

const PlayerEndStats: React.FC<{ slot: number; battle: Battle; winner: boolean }> = ({
  slot,
  battle,
  winner,
}) => {
  return (
    <div
      className={Style("flex w-full h-full items-center justify-center")
        .if(winner, "text-green", "text-red")
        .toString()}
    >
      <span className="font-semibold">
        <Price value={battle.prizes.reduce((accum, x) => accum + x[slot].price, 0)} />
      </span>
    </div>
  );
};

type RoundDetails = {
  current: number;
  offset: number;
};

interface PlayersProps {
  round?: RoundDetails;
  winner?: number;
  battle: Battle;
  prizes?: Prize[];
  done?: () => void;
  join?: (slot: number) => void;
}

const Players: React.FC<PlayersProps> = ({ round, battle, prizes, done, join }) => {
  const [finished, setFinished] = useState<{ [key: number]: boolean }>({});

  useEffect(() => {
    setFinished({});
  }, [round]);

  useEffect(() => {
    if (Object.keys(finished).length !== battle.capacity) {
      return;
    }

    done && done();
    setFinished({});
  }, [finished, battle.capacity, done]);

  const spinnerStates = (index: number): { [key: number]: any } => ({
    0: !battle.players[index] && (
      <div className="flex items-center justify-center w-full h-full">
        <Button className="px-6 py-4" color="green" onClick={() => join && join(index)}>
          JOIN FOR <Price value={battle.cost} />
        </Button>
      </div>
    ),
    1: (
      <div className="flex w-full h-full items-center justify-center text-gray-25">
        <span className="font-semibold uppercase">waiting for random.org...</span>
      </div>
    ),
    2: round && round.current < battle.rounds.length && prizes && (
      <div className="transform translate-y-1/2 h-full">
        <Spinner
          key={round.current * 4 + index}
          seed={
            String(battle.id) +
            String(round.current) +
            String(battle.capacity) +
            String(battle.cost) +
            String(index)
          }
          offset={round.offset}
          prizes={prizes}
          winning={battle.prizes[round.current][index]}
          done={() => setFinished((prev) => ({ ...prev, [index]: true }))}
        />
      </div>
    ),
    3: <PlayerEndStats slot={index} battle={battle} winner={index === 2} />,
  });

  return (
    <>
      {[...Array(battle.capacity)].map((_, index) => (
        <div
          key={index}
          className={Style("w-full")
            .if(index + 1 !== battle.capacity, "mr-8")
            .toString()}
        >
          <div className={"overflow-hidden w-full h-72 rounded bg-gray-800 shadow-inner"}>
            {spinnerStates(index)[battle.state]}
          </div>
          <PlayerDetails user={battle.players[index]} />
          <PlayerPrizes
            slot={index}
            count={battle.rounds.length}
            round={round?.current || -1}
            prizes={battle.prizes}
          />
        </div>
      ))}
    </>
  );
};

const TotalPrizeValue: React.FC<{ battle: Battle }> = ({ battle }) => (
  <Price value={battle.prizes.flat().reduce((accum, x) => accum + x.price, 0)} />
);

const WinModal: React.FC<{
  battle: Battle;
  show: boolean;
}> = ({ battle, show }) => {
  const heading = (
    <>
      TOTAL WINNING OF THIS BATTLE WAS{" "}
      <span className="text-red">
        <TotalPrizeValue battle={battle} />
      </span>
      .
    </>
  );

  return (
    <ModalWithHeading
      show={show}
      className="w-96 mx-auto my-28 px-3 pb-3"
      heading="This battle has ended"
      subheading={heading}
    >
      <div className="flex items-center justify-center mb-6">
        {battle.players.map(
          (player, index) =>
            player && (
              <div
                key={index}
                className={Style("mx-3 rounded")
                  .if(index === 1, "w-12 h-12", "w-10 h-10")
                  .toString()}
              >
                <Avatar className="rounded" size={1} src={player.avatar} />
              </div>
            )
        )}
      </div>
      <div className="flex">
        <Button color="gray" className="w-full h-10 mr-3" href="/battles">
          GO TO BATTLES LIST
        </Button>
        <Button
          href={{ pathname: "/battles/create", state: battle.rounds }}
          color="green"
          className="w-full h-10"
        >
          CREATE SAME BATTLE
        </Button>
      </div>
    </ModalWithHeading>
  );
};

interface ConfirmState {
  show: boolean;
  slot: number | null;
}

const Page = () => {
  const { id } = useParams<{ id: string }>();

  const [battle, setBattle] = useState<BattleDetails | null>(null);
  const [confirm, setConfirm] = useState<ConfirmState>({ show: false, slot: null });

  useFetch<BattleDetails>(`/battle/${id}`, {
    onResponse(data) {
      setBattle(data);
    },
  });

  useChannel<Battle>(CHANNELS.updatedBattle, {
    received(data) {
      if (data.id !== Number(id)) {
        return;
      }

      setBattle((details) => details && { ...details, details: data });
    },
  });

  const [round, setRound] = useState<RoundDetails | undefined>();

  useEffect(() => {
    const offsets = progress(battle?.details);
    offsets !== null && setRound(offsets);
  }, [battle]);

  const prizes = useMemo(() => {
    if (!round || !battle) {
      return;
    }

    const currentCase = battle.details.rounds[round.current];

    if (!currentCase) {
      return;
    }

    return battle.cases[currentCase.id];
  }, [battle, round]);

  const showWinModal = useMemo(() => {
    return round?.current === battle?.details.rounds.length;
  }, [battle, round]);

  return (
    <SkeletonTheme color="#1a1e23" highlightColor="#21242b">
      <Container>
        <JoinConfirmModal
          show={confirm.show}
          battle={battle === null ? undefined : battle.details}
          slot={confirm.slot === null ? undefined : confirm.slot}
          close={() => setConfirm({ show: false, slot: null })}
        />
        {battle && <WinModal battle={battle?.details} show={showWinModal} />}
        <div className="p-3 rounded bg-gray-700">
          {battle ? (
            <Rounds current={round?.current} rounds={battle?.details.rounds} />
          ) : (
            <RoundsSkeleton />
          )}
          <Value className="py-3" textSize="xs" label="Total Value">
            <Price value={battle?.details.cost || 0} />
          </Value>
          <div className={Style("w-full").if(!!battle, "flex").toString()}>
            {battle ? (
              <Players
                round={round}
                battle={battle.details}
                prizes={prizes}
                done={() => setRound((round) => round && { current: round.current + 1, offset: 0 })}
                join={(slot) => setConfirm({ show: true, slot })}
              />
            ) : (
              <Skeleton height={524} />
            )}
          </div>
        </div>
      </Container>
    </SkeletonTheme>
  );
};

export default Page;
