import { useLayoutEffect, useRef } from "react";

type ResumableOptions = {
  offset: number;
  durations: number[];
  onTransition: (index: number) => void;
  onChange: (elapsed: number, index: number) => void;
  onEnding: () => void;
};

const cumulative = (xs: number[]) =>
  xs.reduce((x: number[], a: number) => (x.length ? [...x, x[x.length - 1] + a] : [a]), []);

const findStage = (offset: number, durations: number[]) => {
  let i, last;
  i = last = 0;

  for (const current of cumulative(durations)) {
    if (offset > current) {
      last = current;
      ++i;
    }
  }

  return [i, (offset - last) / durations[i]];
};

export const useResumableAnimationFrame = (
  options: ResumableOptions,
  deps?: React.DependencyList
) => {
  const index = useRef(0);
  const startingProgress = useRef(0);

  useLayoutEffect(() => {
    let start: number, previousTimeStamp: number;
    let frame: number;

    [index.current, startingProgress.current] = findStage(options.offset, options.durations);

    for (let i = 0; i < index.current; ++i) {
      options.onTransition(i);
    }

    const elapsedtime = (start: number, time: number) => {
      const duration = options.durations[index.current];

      return (
        startingProgress.current +
        Math.min((time - start) / (duration * (1 - startingProgress.current)), 1) *
          (1 - startingProgress.current)
      );
    };

    const step = (time: number) => {
      if (start === undefined) {
        start = time;
      }

      const elapsed = elapsedtime(start, time);

      if (elapsed === 1) {
        if (options.durations.length === index.current + 1) {
          return options.onEnding();
        }

        start = time;
        options.onTransition(index.current);
        startingProgress.current = 0;
        ++index.current;
        return (frame = window.requestAnimationFrame(step));
      }

      previousTimeStamp !== time && options.onChange(elapsed, index.current);
      previousTimeStamp = time;

      frame = window.requestAnimationFrame(step);
    };

    frame = window.requestAnimationFrame(step);
    return () => window.cancelAnimationFrame(frame);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
};
