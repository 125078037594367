import React, { useRef } from "react";
import { useEffect } from "react";
import { useState } from "react";

interface RippleStyle {
  top: string;
  left: string;
  height: string;
  width: string;
}

interface Ripple {
  style: RippleStyle;
  timeout: NodeJS.Timeout;
  key: number;
}

interface RippleProps {
  clicked?: (x: boolean) => void;
}

const Ripple: React.FC<RippleProps> = ({ clicked }) => {
  const [styles, setStyles] = useState<Ripple[]>([]);

  const componentWillUnmount = useRef(false);

  useEffect(() => {
    setStyles([]);

    return () => {
      componentWillUnmount.current = true;
    };
  }, []);

  useEffect(() => {
    return () => {
      if (!componentWillUnmount.current) {
        return;
      }

      for (const { timeout } of styles) {
        clearTimeout(timeout);
      }
    };
  }, [styles]);

  const create = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    const container = e.currentTarget;

    const [width, height] = [container.offsetWidth * 0.2, container.offsetHeight];
    const bounds = container.getBoundingClientRect();

    const [scrollLeft] = [
      document.body.scrollLeft + document.documentElement.scrollLeft,
      document.body.scrollTop + document.documentElement.scrollTop,
    ];

    const [x, y] = [e.pageX - bounds.x - width / 2 - scrollLeft, 0];

    const key = Date.now();

    const style = {
      top: `${y}px`,
      left: `${x}px`,
      height: `${height}px`,
      width: `${width}px`,
    };

    clicked && clicked(true);

    const timeout = setTimeout(() => {
      setStyles((styles) => {
        if (styles.length === 1) {
          clicked && clicked(true);
        }

        // console.log("called cleanup", JSON.stringify(styles));

        return styles.filter((value) => value.key !== key);
      });
    }, 1000);

    setStyles((styles) => [...styles, { style, timeout, key }]);
  };

  const ripples = () => {
    return styles.map(({ style, key }) => (
      <div
        key={key}
        className="absolute animate-ripple bg-gray-100 opacity-10"
        style={{ top: style.top, left: style.left, height: style.height, width: style.width }}
      ></div>
    ));
  };

  return (
    <div className="absolute inset-0" onMouseDown={create}>
      {ripples()}
    </div>
  );
};

export default Ripple;
