import React from "react";
import ReactDOM from "react-dom";
import { SkeletonTheme } from "react-loading-skeleton";

import App from "./App";
import "./index.generated.css";

ReactDOM.render(
  <React.StrictMode>
    <SkeletonTheme color="#1d2126" highlightColor="#21242b">
      <App />
    </SkeletonTheme>
  </React.StrictMode>,
  document.getElementById("root")
);
