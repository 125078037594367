import React from "react";

import { ReactComponent as G2APay } from "../icons/g2apay.svg";
import { ReactComponent as Mastercard } from "../icons/mastercard.svg";
import { ReactComponent as Visa } from "../icons/visa.svg";

const Footer: React.FC<{}> = () => (
  <div className="flex flex-col items-center w-full p-7 bg-gray-800">
    <div className="flex-1"></div>
    <div className="flex items-center justify-center h-16 -mb-1">
      <Visa className="h-16 w-20 fill-current text-blue-600" />
      <Mastercard className="h-16 w-20 mx-4" />
      <G2APay className="h-16 w-20 ml-1.5" />
    </div>
    <div className="my-6 text-sm text-gray-50">
      {["Fairness", "Refferals", "Help", "Support", "Privacy Policy", "Terms of Service"].map(
        (name, index) => (
          <a key={index} href="/" className="px-2 hover:text-gray-25">
            {name}
          </a>
        )
      )}
    </div>
    <div className="text-center text-tiny text-gray-50">
      <p>Copyright © 2016 - 2020 rust. All rights reserved.</p>
      <p className="pb-2">
        Moonrail Limited B.V., Fransche Bloemweg 4, Curaçao (Company Registration No. 148182). A
        company licensed and regulated by the laws of Curaçao under license number 1668/JAZ
      </p>
      <p>
        Payments may be handled on behalf of Moonrail Limited B.V. by JHOLT LTD, Voukourestiou, 25,
        NEPTUNE HOUSE, 1st floor, Flat/Office 11, 3045, Limassol, Cyprus (Company Registration No.
        HE393291) as per the agreement between the two companies.
      </p>
    </div>
  </div>
);

export default Footer;
