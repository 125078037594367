import ReconnectingWebSocket from "reconnectingwebsocket";

let timeout: NodeJS.Timeout;

const SYNC_TIME = () => 6135 + Math.random() * 1592;
const SYNC_ID = () => (Math.random() * 1e5) | 0;

const sync_map: { [key: number]: { now: number } } = {};

let offset = 0;
export const offsetToServer = () => offset;

const sync = (socket: ReconnectingWebSocket) => {
  const id = SYNC_ID();

  socket.send(JSON.stringify(["sync", id]));
  sync_map[id] = { now: Date.now() };

  timeout = setTimeout(() => sync(socket), SYNC_TIME());
};

export const listen = (socket: ReconnectingWebSocket) => {
  socket.addEventListener("message", (event) => {
    const [tag, identifier, timestamp] = JSON.parse(event.data);

    if (tag !== "sync" && !sync_map[identifier]) {
      return;
    }

    let round_trip = Date.now() - sync_map[identifier].now;
    offset = Date.now() - timestamp + round_trip / 2;
  });
};

export const start = (socket: ReconnectingWebSocket) => {
  sync(socket);
};

export const pause = () => {
  clearTimeout(timeout);
};
