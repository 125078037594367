import React, { useLayoutEffect, useRef, useState } from "react";
import { useResumableAnimationFrame } from "../../utils/animation";
import BezierEasing from "bezier-easing";
import { Style } from "../../utils";
import seedrandom from "seedrandom";

import { Battle, Item, Prize } from "../../types";
import { ItemImage } from "../item";
import Price from "../Price";
import { offsetToServer } from "../../socket/sync";

const BLOCKS = 35;
const BLOCK_SIZE = 96;
const BLOCK_MARGIN = 36;
const WIN_TARGET = 32;

export const ROUND_TIME = 5200 + 600 + 2200;

const easings = {
  spinner: BezierEasing(0.3, 0, 0.24, 1),
  alignment: BezierEasing(0.22, 1, 0.36, 1),
};

const sample = (prizes: Prize[], rand: number) => {
  const ticket = Math.floor(rand * 1_000_000) + 1;
  const found = prizes.find((prize) => prize.low_ticket <= ticket && prize.high_ticket >= ticket);
  return found || prizes[0];
};

export const progress = (battle?: Battle) => {
  if (!battle || battle.state !== 2 || !battle.started_at) {
    return null;
  }

  const elapsed = Date.now() - offsetToServer() - Number(new Date(battle.started_at));
  const current = Math.min(Math.floor(elapsed / ROUND_TIME), battle.rounds.length);

  return { current, offset: elapsed - current * ROUND_TIME };
};

const Block: React.FC<{ prize: Prize; active?: boolean; ended?: boolean }> = ({
  prize,
  active,
  ended,
}) => (
  <div
    className={Style("relative flex items-center h-24 mb-9")
      .if(active && ended, "justify-center")
      .toString()}
  >
    <ItemImage
      className={Style("transform w-20 h-20 transition duration-500")
        .if(active, "scale-125", "opacity-20")
        .toString()}
      name={prize.item.name}
      image={prize.item.image}
    />
    <div
      className={Style("flex ml-6 w-48 flex-col duration-300", "text-xs text-gray-25")
        .if(!active || !ended, "opacity-0 hidden")
        .toString()}
    >
      <span className="mr-3 uppercase overflow-ellipsis overflow-hidden whitespace-nowrap">
        {prize.item.name}
      </span>
      <span className="text-red font-semibold">
        <Price value={prize.item.price} />
      </span>
    </div>
  </div>
);

interface SpinnerProps {
  seed?: string;
  offset: number;
  winning?: Item;
  done?: () => void;
  prizes: Prize[];
}

interface Measurement {
  start: number;
  spin: number;
  align: number;
}

const getMeasurements = (index: number, random: number): Measurement => {
  const start = BLOCK_SIZE + BLOCK_MARGIN + BLOCK_SIZE / 2;
  const origin = (BLOCK_SIZE + BLOCK_MARGIN) * index + BLOCK_SIZE / 2;
  const offset = (Math.max(Math.min(random, 0.8), 0.2) - 0.5) * (BLOCK_SIZE + BLOCK_MARGIN);

  return { start, spin: origin + offset, align: origin };
};

const getActiveIndex = (value: number) => {
  return (-(value - BLOCK_MARGIN / 2) / (BLOCK_SIZE + BLOCK_MARGIN)) | 0;
};

const Spinner: React.FC<SpinnerProps> = ({ seed, prizes, winning, offset, done }) => {
  const [blocks, setBlocks] = useState<Prize[]>([]);
  const [measurements, setMeasurements] = useState<Measurement | null>(null);

  /* Seed random number generator specific to this spinner.
     Initialize all important state. */
  useLayoutEffect(() => {
    const prng = seedrandom(seed);

    /* Randomly generate the prizes on the spinner.
         If there is a known winner replace the winning index with it. */
    const samples = [...Array(BLOCKS)].map(() => sample(prizes, prng()));

    samples[WIN_TARGET] = winning ? { ...samples[WIN_TARGET], item: winning } : samples[WIN_TARGET];

    setBlocks(samples);
    setMeasurements(getMeasurements(WIN_TARGET, prng()));
  }, [prizes, seed, winning]);

  const spinnerRef = useRef<HTMLDivElement>(null);

  const [activeIndex, setActiveIndex] = useState(0);
  const [ended, setEnded] = useState(false);

  useResumableAnimationFrame(
    {
      offset,
      durations: [5_200, 600, 2_200],
      onTransition(index) {
        const transitions = [() => setEnded(false), () => setEnded(true)];
        transitions[index] && transitions[index]();
      },
      onChange(elapsed, step) {
        if (!spinnerRef.current || !measurements) {
          return;
        }

        const transitions = [
          () =>
            measurements.start +
            (measurements.spin - measurements.start) * easings.spinner(elapsed),
          () =>
            measurements.spin +
            (measurements.align - measurements.spin) * easings.alignment(elapsed),
          () => measurements.align,
        ];

        spinnerRef.current.style.transform = `translateY(-${transitions[step]()}px)`;

        setActiveIndex(getActiveIndex(-transitions[step]()));
      },
      onEnding() {
        done && done();
      },
    },
    [measurements]
  );

  return (
    <div ref={spinnerRef} className="w-24 mx-auto">
      {blocks.map((prize, index) => (
        <Block key={index} prize={prize} active={index === activeIndex} ended={ended} />
      ))}
    </div>
  );
};

export default Spinner;
