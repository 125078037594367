import React from "react";

const Checkbox: React.FC<{ label: string; className?: string }> = ({ label, className }) => (
  <button className={"flex items-center " + className}>
    <div className="w-3 h-3 mr-1.5 border border-gray-400 rounded-sm"></div>
    <span className="mb-0.5">{label}</span>
  </button>
);

export default Checkbox;
