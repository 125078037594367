import React from "react";

const Level: React.FC<{
  level: number;
  progress: number;
  className?: string;
}> = ({ level, progress, className }) => (
  <div
    className={`${className} flex items-center mr-3 font-semibold text-tiny text-gray-50`}
  >
    Level {level}
    <div className="relative w-48 h-1.5 ml-2 bg-gray-500 rounded-lg">
      <div
        className="absolute inset-0 bg-red-500 rounded-lg"
        style={{ width: `${progress * 100}%` }}
      ></div>
    </div>
  </div>
);

export default Level;
