import React from "react";

const CaseImage: React.FC<{
  src: string;
  name: string;
  className?: string;
}> = ({ name, className }) => {
  const src = "https://static.ancientgaming.io/images/prisma.png";
  // "https://datdrop.com/content/images/dropcases/165_background_312965_300.png";

  return <img src={src} alt={name} className={className} />;
};

export default CaseImage;
