import React from "react";
import { Style } from "../utils";

const Option: React.FC<{
  value: number;
  active?: boolean;
  disabled?: boolean;
  className?: string;
  onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}> = ({ value, active = false, className, onClick, disabled = false }) => (
  <button
    onClick={onClick}
    className={Style(
      "flex items-center justify-center font-semibold border-red-500 rounded",
      className
    )
      .if(disabled, "opacity-50", "cursor-pointer")
      .if(active, "border bg-opacity-50 bg-red-500 text-gray-25", "bg-gray-500 text-gray-25")
      .toString()}
    disabled={disabled}
  >
    {value}
  </button>
);

const Range: React.FC<{
  range: [number, number];
  count: number;
  onClick: (count: number) => void;
  optionClassName?: string;
  disabled: boolean;
}> = ({ range: [low, high], count, optionClassName, onClick, disabled }) => {
  let amountSelects = [];

  for (let i = low; i <= high; i++) {
    amountSelects.push(
      <Option
        key={i}
        value={i}
        className={optionClassName}
        active={count === i}
        disabled={disabled}
        onClick={() => onClick(i)}
      />
    );
  }

  return <div className="flex items-center my-3">{amountSelects}</div>;
};

export default Range;
