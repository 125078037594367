import { useEffect, useState } from "react";
import ReconnectingWebSocket from "reconnectingwebsocket";
import * as Sync from "./sync";

const SOCKET_URL =
  process.env.NODE_ENV !== "production" ? "ws://localhost:8080" : "ws://172.105.246.75/feed";

const socket = new ReconnectingWebSocket(SOCKET_URL);

type Callback = (message: any) => void;

const callbacks: { [key: string]: Callback } = {};

Sync.listen(socket);

socket.addEventListener("open", () => {
  Sync.start(socket);

  for (const channel in callbacks) {
    socket.send(JSON.stringify(["subscribe", channel]));
  }
});

socket.addEventListener("close", () => {
  Sync.pause();
});

socket.addEventListener("message", (event) => {
  const [channel, message] = JSON.parse(event.data);

  callbacks[channel] && callbacks[channel](message);
});

const sendSafely = (command: string, channel: string) => {
  const sending = JSON.stringify([command, channel]);

  if (socket.readyState === 1) {
    socket.send(sending);
  }
};

const subscribe = (channel: string) => {
  sendSafely("subscribe", channel);
};

const unsubscribe = (channel: string) => {
  sendSafely("unsubscribe", channel);
  delete callbacks[channel];
};

type ChannelOptions<T> = {
  received?: (data: T) => void;
};

export const CHANNELS = {
  livefeed: "livefeed",
  balance: (steamid: string) => `${steamid}#balance`,
  createdBattle: "battle#created",
  updatedBattle: "battle#updated",
  deletedBattle: "battle#deleted",
};

export const useChannel = <T>(channel?: string | null, options?: ChannelOptions<T>) => {
  const [state, setState] = useState<{ last: T | null }>({ last: null });

  useEffect(() => {
    if (!channel) {
      return;
    }

    subscribe(channel);
    setState({ last: null });

    callbacks[channel] = (last: T) => {
      setState({ last });
      options?.received && options.received(last);
    };

    return () => {
      unsubscribe(channel);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [channel]);

  return [state.last];
};
