import React, { useState } from "react";
import { Link } from "react-router-dom";
import { LocationDescriptor } from "history";
import { ReactClickEvent } from "../../types";
import { Style } from "../../utils";
import Ripple from "./Ripple";

type Location = LocationDescriptor<any>;

const Href: React.FC<{
  children: React.ReactNode;
  className?: string;
  href: Location;
}> = ({ children, className, href }) => (
  <Link className={className} to={href}>
    {children}
    <Ripple />
  </Link>
);

interface BasicProps {
  children: React.ReactNode;
  disabled?: boolean;
  onClick?: (event: ReactClickEvent) => void;
  className?: string;
}

const Basic = React.forwardRef<HTMLButtonElement, BasicProps>(
  ({ children, disabled, className, onClick }, ref) => (
    <button ref={ref} className={className} disabled={disabled} onClick={onClick}>
      {children}
      <Ripple />
    </button>
  )
);

type Color = "red" | "green" | "gray";

interface ButtonProps {
  children: React.ReactNode;
  color?: Color;
  hoverEffect?: boolean;
  className?: string;
  disabled?: boolean;
  onClick?: (event: ReactClickEvent) => void;
  href?: Location;
}

const COLORS = {
  red: ["bg-red-500 text-gray-25", "shadow-red"],
  green: ["bg-green-500 text-gray-10", "shadow-green"],
  gray: ["bg-gray-500 text-gray-25", "shadow-gray"],
};

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ children, hoverEffect, disabled, color, className, onClick, href }, ref) => {
    const [floating] = useState(false);

    const style = Style(
      "relative overflow-hidden",
      className,
      "flex items-center justify-center",
      "transform",
      "rounded active:shadow-none",
      "font-semibold text-xs",
      color && COLORS[color][0],
      color && !disabled && COLORS[color][1]
    )
      .if(!disabled, "active:translate-y-px", "opacity-50 cursor-default")
      .if(hoverEffect && floating, "hover:-translate-y-px");

    const common = {
      children,
      className: style.toString(),
    };

    return href ? (
      <Href href={href} {...common} />
    ) : (
      <Basic ref={ref} onClick={onClick} disabled={disabled} {...common} />
    );
  }
);

export default Button;
export type { Color };
