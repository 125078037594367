import React from "react";
import { round } from "../../utils";
import { User } from "../../types";

import Button from "../button";
import Price from "../Price";
import Avatar from "../Avatar";

import { ReactComponent as Steam } from "../../icons/steam.svg";
import { ReactComponent as Plus } from "../../icons/plus.svg";

const formatItemTotal = (total: number): string => {
  if (total < 10) {
    return round(total, 2);
  }

  if (total < 100) {
    return round(total, 1);
  }

  return round(total, 0);
};

const Login: React.FC<{ user: User | null }> = ({ user }) =>
  user ? (
    <>
      <div className="flex items-center h-9 mr-3 pl-2 ml-auto cursor-pointer text-gray-25 text-xs rounded bg-gray-900 shadow-inner">
        <Price value={user.balance} />
        <Button color="red" className="flex items-center justify-center w-9 h-9 ml-2">
          <Plus className="w-5 h-5 text-gray-90 fill-current" />
        </Button>
      </div>
      <div className="relative mr-3">
        <div className="absolute -top-1 -right-1 px-0.5 rounded font-semibold text-tiny text-gray-90 bg-gray-200 z-10">
          ${formatItemTotal(user.assets / 100)}
        </div>
        <Button href={`/profile/${user.steamid}`} color="gray" className="h-9 py-2 px-8">
          INVENTORY
        </Button>
      </div>
      <Button href={`/profile/${user.steamid}`} color="gray" className="h-9 mr-3 py-2 px-2">
        <Avatar
          className="h-5 w-5 mr-1.5 rounded"
          src={user.avatar}
          size={0}
          name={`${user.username}'s avatar`}
        />
        {user.username}
      </Button>
    </>
  ) : (
    <Button
      className="h-9 ml-auto mr-3 py-2 px-4 bg-red-500 shadow-red font-semibold text-xs text-gray-90"
      onClick={() => window.open("/api/steam/login")}
      color="red"
      hoverEffect
    >
      <Steam className="h-5 mr-1 fill-current text-gray-90"></Steam>
      SIGN IN
    </Button>
  );

export default Login;
