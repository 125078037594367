import React from "react";

import Price from "../Price";
import { ItemImage } from "../item";
import CaseImage from "../CaseImage";
import Avatar from "../Avatar";

import { LivefeedCard, Tier } from "../../types";
import { tierOfPrice } from "../../utils";
import SectionHeading from "../SectionHeading";

const Card: React.FC<{ card: LivefeedCard; animate: boolean }> = ({
  card: {
    item: { name, image, price },
    case: cardCase,
    user,
  },
  animate,
}) => {
  const tier = tierOfPrice(price);

  return (
    <div
      className={`relative flex-none h-40 w-32 mr-2 perspective ${
        animate ? "animate-cardexpand" : ""
      }`}
    >
      <a
        className={`group absolute h-40 w-32 transform right-0 rounded ${
          tier === Tier.LEGENDARY ? "border border-legendary" : ""
        } bg-gray-550 font-semibold text-gray-25 origin-right ${
          animate ? "animate-cardslide" : ""
        } perspective`}
        href="/lol"
      >
        <div
          className={`absolute inset-0 w-full h-4/5 group-hover:h-full opacity-25 rounded group-hover:opacity-40 duration-100 bg-gradient-to-b from-${tier}`}
        ></div>
        <div className="relative w-full h-7 group-hover:rotate-y-180 duration-300 ease-out preserve-3d z-10">
          <div className="absolute flex items-center w-full h-full px-2 rotate-y-0 backface-invisible font-semibold text-tiny">
            <Price value={price} />
          </div>
          <div className="absolute flex items-center w-full h-full px-1 rotate-y-180 backface-invisible font-semibold text-tiny">
            <Avatar className="flex-none w-5 h-5 mr-1 rounded" size={0} src={user.avatar} />
            <span className="truncate">{user.username}</span>
          </div>
        </div>
        <div className="flex justify-center items-center relative h-20 w-24 group-hover:rotate-y-180 m-auto duration-200 ease-out preserve-3d z-10">
          <ItemImage className="absolute w-20 h-20 backface-invisible" image={image} name={name} />
          <CaseImage
            src={cardCase.image}
            name={cardCase.name}
            className="absolute w-full rotate-y-180 backface-invisible"
          />
        </div>
        <div className="absolute w-full bottom-0 px-3 pb-3 break-normal text-center text-xs z-10">
          {name.toUpperCase()}
        </div>
      </a>
    </div>
  );
};

const LiveFeed: React.FC<{ cards: { card: LivefeedCard; animate: boolean }[] }> = ({ cards }) => (
  <>
    <SectionHeading>
      <div className="w-2 h-2 mr-1 bg-red-500 rounded"></div>
      LIVE DROPS
    </SectionHeading>
    <div className="flex w-full overflow-hidden pl-3">
      {cards.map(({ card, animate }) => (
        <Card key={card.id} card={card} animate={animate} />
      ))}
    </div>
  </>
);

export default LiveFeed;
