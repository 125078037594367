import React from "react";
import { useHistory } from "react-router-dom";
import { useAction } from "../../utils";

import { ModalWithHeading } from "../Modal";
import Avatar from "../Avatar";
import Button from "../button";
import Checkbox from "../Checkbox";
import Price from "../Price";

import { Battle } from "../../types";

const owner = (battle?: Battle) =>
  battle?.players.find((player) => player.steamid === battle.owner);

const BattleAvatar: React.FC<{ className?: string; size: number; battle?: Battle }> = ({
  className,
  size,
  battle,
}) => <Avatar src={owner(battle)?.avatar} className={className} size={size} />;

const JoinConfirmModal: React.FC<{
  show: boolean;
  battle?: Battle;
  slot?: number;
  close: () => void;
}> = ({ show, battle, close, slot }) => {
  const history = useHistory();

  const [join] = useAction<{}>("/battle/" + battle?.id, {
    done() {
      history.push("/battle/" + battle?.id);
    },
  });

  const heading = (
    <>
      JOIN BATTLE AGAINST <span className="text-red">{owner(battle)?.username} </span>?
    </>
  );

  return (
    <ModalWithHeading show={show} className="w-96 mx-auto my-28 px-3 pb-3" heading={heading}>
      <div className="flex justify-center w-full mb-3">
        <BattleAvatar battle={battle} size={1} className="w-10 h-10 rounded" />
      </div>
      <Checkbox label="Don't ask me again." className="text-xs ml-1 mb-3 text-gray-40" />
      <div className="flex">
        <Button onClick={close} color="gray" className="w-full h-10 mr-3">
          CANCEL
        </Button>
        <Button
          onClick={() => {
            join({ slot: slot || null });
            close();
          }}
          color="green"
          className="w-full h-10"
        >
          JOIN FOR <Price value={battle?.cost || 0} />
        </Button>
      </div>
    </ModalWithHeading>
  );
};

export default JoinConfirmModal;
