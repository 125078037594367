import React, { useMemo, useRef, useState } from "react";
import BezierEasing from "bezier-easing";

import { ItemImage } from "../item";
import Chance from "./Chance";
import { tierOfPrice } from "../../utils";

import { Prize } from "../../types";
import { useEffect } from "react";

const BLOCK_SIZE = 176 + 4;
const HALF = BLOCK_SIZE / 2;
const TOTAL_BLOCKS = 48;

const Block: React.FC<{ item: Prize; lit: boolean }> = ({
  item: {
    item: { name, image, price },
    low_ticket,
    high_ticket,
  },
  lit,
}) => {
  const tier = tierOfPrice(price);

  return (
    <div
      className={`relative w-44 h-44 mr-1 pt-4 px-3 overflow-y-hidden flex-none rounded ${
        lit ? "border-t-2 border-b-2" : "opacity-60"
      } border-${tier} bg-gray-700 duration-100`}
    >
      <div
        className={`absolute w-full h-64 inset-0 rounded ${
          lit ? "opacity-40" : "opacity-40"
        } bg-gradient-to-b from-${tier} duration-100`}
      ></div>
      <ItemImage className="flex-1 w-28 h-28 mx-auto relative z-10" name={name} image={image} />
      <div className="absolute flex items-center w-full px-3 pb-2.5 left-0 bottom-0 font-semibold text-sm text-gray-25 z-10">
        <span className="truncate">{name}</span>
        <Chance className="ml-auto text-xs" tickets={[low_ticket, high_ticket]} />
      </div>
    </div>
  );
};

const sample = (possible: Prize[]) => {
  const ticket = ((Math.random() * 1000000) | 0) + 1;

  for (const item of possible) {
    if (item.low_ticket > ticket || item.high_ticket < ticket) {
      continue;
    }
    return item;
  }

  return possible[0];
};

const arrange = (
  possible: Prize[],
  previous: Prize[] = [],
  target?: { item: Prize; index: number }
) => {
  const result = [];

  if (!possible.length) {
    return [];
  }

  for (let i = 0; i < TOTAL_BLOCKS; ++i) {
    const sampled = i < previous.length ? previous[i] : sample(possible);

    if (target && i === target.index) {
      result.push(target.item);
      continue;
    }

    result.push(sampled);
  }

  return result;
};

const Row: React.FC<{
  items: Prize[];
  target?: Prize;
  spinning: boolean;
  parentWidth: number;
  done?: () => void;
}> = ({ items, spinning, parentWidth, done, target }) => {
  const [blocks, setBlocks] = useState<Prize[]>([]);
  const [endStrip, setEndStrip] = useState<Prize[]>([]);
  const [activeBlock, setActiveBlock] = useState(0);
  const [endOffset, setEndOffset] = useState(0);

  const easing = useMemo(() => BezierEasing(0.24, 0.07, 0.14, 1), []);
  const ref = useRef<HTMLDivElement>(null);

  const padBlocks = Math.floor(parentWidth / BLOCK_SIZE / 2) + 1;

  useEffect(() => {
    if (ref && ref.current) {
      const offset = padBlocks * BLOCK_SIZE + HALF;
      ref.current.style.transform = `translateX(-${offset}px)`;
    }
  }, [ref, padBlocks]);

  React.useLayoutEffect(() => {
    setBlocks((blocks) =>
      arrange(items, blocks, target && { item: target, index: TOTAL_BLOCKS - padBlocks - 1 })
    );
  }, [items, target, padBlocks]);

  React.useLayoutEffect(() => {
    if (!spinning) return;

    let newBlocks: Prize[];
    if (endStrip.length) {
      newBlocks = arrange(
        items,
        endStrip,
        target && { item: target, index: TOTAL_BLOCKS - padBlocks - 1 }
      );
      setBlocks(newBlocks);
    }

    /* Don't land on edges, so the children don't cry */
    let surprize = Math.random();
    surprize = surprize > 0.98 ? 0.98 : surprize;
    surprize = surprize < 0.04 ? 0.04 : surprize;

    const distance = (TOTAL_BLOCKS - padBlocks * 2 - 1 - surprize + 0.5) * BLOCK_SIZE;
    let start: number | null = null;

    const f = (time: number) => {
      if (start === null) {
        start = time;
      }

      const norm = (time - start) / 6e3;
      const progress = easing(norm > 1 ? 1 : norm);

      const extended = endOffset + progress * (distance - endOffset);

      const blockIndex = Math.floor(extended / BLOCK_SIZE + 0.5) + padBlocks;
      const offset = padBlocks * BLOCK_SIZE + HALF + extended;

      if (blockIndex !== activeBlock) {
        setActiveBlock(blockIndex);
      }

      if (ref && ref.current) {
        ref.current.style.transform = `translateX(-${offset}px)`;
      }

      if (norm >= 1) {
        setEndOffset(((distance + HALF) % BLOCK_SIZE) - HALF);

        const endLowerIndex = Math.floor(distance / BLOCK_SIZE + 0.5);
        const endUpperIndex = Math.floor(endLowerIndex + padBlocks * 2) + 1;

        const currentBlock = newBlocks || blocks;
        setEndStrip(currentBlock.slice(endLowerIndex, endUpperIndex + 1));

        done && done();
      } else {
        requestAnimationFrame(f);
      }
    };

    const frame = requestAnimationFrame(f);
    return () => cancelAnimationFrame(frame);
  }, [spinning, done, padBlocks, activeBlock, blocks, easing, endOffset, endStrip, items, target]);

  return (
    <div ref={ref} className="flex">
      {blocks.map((item, index) => (
        <Block key={index} item={item} lit={index === activeBlock || !spinning} />
      ))}
    </div>
  );
};

export default Row;
