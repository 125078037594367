import React from "react";
import { Style } from "../utils";

const Avatar: React.FC<{
  name?: string;
  src?: string;
  size: number;
  className?: string;
}> = ({ name = "loading...", size, src = "", className }) => {
  const sizes = ["", "_medium", "_full"];

  const clipped = src.replace(/\.jpg/, "");

  return src ? (
    <img src={`${clipped}${sizes[size]}.jpg`} alt={name} className={className} />
  ) : (
    <div className={Style("bg-gray-800 shadow-inner", className).toString()}></div>
  );
};

export default Avatar;
