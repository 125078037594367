import React, { useState, createRef, useLayoutEffect } from "react";
import Row from "./Row";
import { Prize } from "../../types";

enum SpinType {
  NORMAL,
  DEMO,
}

const Indicator: React.FC<{ spinning: boolean }> = ({ spinning }) => (
  <div
    className={`absolute left-2/4 top-0 transform-x-center h-full ${
      spinning ? "opacity-100" : "opacity-0"
    } w-0.5 bg-red-400 duration-300 z-10`}
  ></div>
);

const CaseSpinner: React.FC<{
  items: Prize[];
  targets?: Prize[];
  count: number;
  type: SpinType;
  spinning: boolean;
  done?: (type: SpinType) => void;
}> = ({ items, count, spinning, done = () => {}, targets, type }) => {
  const container = createRef<HTMLDivElement>();
  const [width, setWidth] = useState(-1);

  useLayoutEffect(() => {
    if (!container.current) {
      return;
    }

    setWidth(container.current.offsetWidth);
  }, [count, container]);

  return (
    <div
      ref={container}
      className="relative w-full py-5 px-2 mt-4 overflow-x-hidden bg-gray-800 shadow-inner rounded"
    >
      <Indicator spinning={spinning} />
      <div className="grid gap-2 relative left-2/4">
        {[...Array(count)].map((_, index) => (
          <Row
            key={index + 12 * count}
            items={items}
            target={targets && targets[index]}
            spinning={spinning}
            parentWidth={width}
            done={() => done(type)}
          />
        ))}
      </div>
    </div>
  );
};

export { SpinType };
export default CaseSpinner;
