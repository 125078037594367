import React from "react";

import { ItemImage } from "../item";
import Chance from "./Chance";
import Price from "../Price";

import { Prize } from "../../types";
import { tierOfPrice } from "../../utils";

const Block: React.FC<{ item: Prize }> = ({
  item: {
    item: { name, price, image },
    low_ticket,
    high_ticket,
  },
}) => (
  <div className="relative h-44 pt-4 bg-gray-700 rounded">
    <ItemImage className="w-24 h-24 mx-auto" name={name} image={image} />
    <div className="absolute w-full bottom-2 left-0 px-3 font-semibold text-xs text-gray-25">
      <span className="">{name.toUpperCase()}</span>
      <div className="flex justify-between w-full">
        <span className={`text-sm text-${tierOfPrice(price)}`}>
          <Price value={price} />
        </span>
        <Chance tickets={[low_ticket, high_ticket]} />
      </div>
    </div>
  </div>
);

const Showcase: React.FC<{ items: Prize[] }> = ({ items }) => (
  <div className="grid gap-3 grid-cols-case-showcase">
    {items.map((item, index) => (
      <Block key={index} item={item} />
    ))}
  </div>
);

export default Showcase;
