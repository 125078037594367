import axios from "axios";
import { useEffect, useState, useCallback } from "react";
import {} from "./animation";
import { Tier } from "../types";
import Style from "./style";

export const REACT_API_PATH = "/api";

export const round = (number: number, places: number) => {
  return Number(Math.round(Number(number + "e+" + places)) + "e-" + places).toFixed(places);
};

export { Style };

export const tierOfPrice = (price: number) => {
  const lookup: [number, Tier][] = [
    [0, Tier.COMMON],
    [400, Tier.UNCOMMON],
    [1000, Tier.RARE],
    [5000, Tier.EPIC],
    [15000, Tier.LEGENDARY],
  ];

  let last = Tier.COMMON;
  for (const [amount, tier] of lookup) {
    if (price < amount) {
      break;
    }
    last = tier;
  }

  return last;
};

type FetchOptions<T> = {
  onResponse?: (data: T) => void;
};

export const useFetch = <T>(url: string, options?: FetchOptions<T>): [T | null, boolean] => {
  const [state, setState] = useState<{ data: T | null; loading: boolean }>({
    data: null,
    loading: true,
  });

  useEffect(() => {
    setState((state) => ({ data: state.data, loading: true }));
    axios
      .get(REACT_API_PATH + url)
      .then((response) => response.data)
      .then((data) => {
        let done = () => {
          setState({ data, loading: false });
          options?.onResponse && options.onResponse(data);
        };

        process.env.NODE_ENV !== "production" ? setTimeout(() => done(), 200) : done();
      })
      .catch((err) => {
        console.log(err);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url]);

  return [state.data, state.loading];
};

type ActionOptions<T> = {
  done?: (data: T) => void;
  format?: (data: any) => T;
};

type Action<T> = [(data?: any) => void, T | null, boolean];

export const useAction = <T>(url: string, options?: ActionOptions<T>): Action<T> => {
  const [state, setState] = useState<{ data: T | null; loading: boolean }>({
    data: null,
    loading: false,
  });

  const action = useCallback(
    (data?: any) => {
      setState({ data: null, loading: true });
      axios
        .post(REACT_API_PATH + url, data)
        .then((response) => response.data)
        .then((json) => {
          const data = (options?.format && options.format(json)) || json;
          setState({ data, loading: false });
          options?.done && options.done(data);
        })
        .catch((err) => {
          console.error("ACTION ERROR", err);
          setState({ data: null, loading: false });
        });
    },
    [url, options]
  );

  return [action, state.data, state.loading];
};
