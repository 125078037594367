import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import { useStore } from "../store";
import { useFetch } from "../utils";

import Button from "../components/button";
import LiveFeed from "../components/livefeed";
import Avatar from "../components/Avatar";
import Price from "../components/Price";
import Skeleton from "react-loading-skeleton";
import { Rounds, Header, JoinConfirmModal } from "../components/battle";

import { Battle, User } from "../types";
import { useRef } from "react";
import { CHANNELS, useChannel } from "../socket";
import { progress, ROUND_TIME } from "../components/battle/Spinner";

const getOwnerDetails = (battle: Battle) => {
  return battle.players.find((player) => player.steamid === battle.owner);
};

const Players = {
  count: (xs: User[]) => xs.filter((x) => !!x).length,
};

const Status: React.FC<{
  players: User[];
  capacity: number;
}> = ({ players, capacity }) => {
  let avatars = [];
  for (let i = 0; i < capacity; ++i) {
    avatars[i] = players[i] ? players[i].avatar : null;
  }

  return (
    <div className="flex flex-col items-center justify-center">
      <span className="mb-2 text-gray-25 text-xs font-semibold">
        {Players.count(players)}/{capacity}
      </span>
      <div className="flex justify-center flex-shrink-0 flex-wrap w-16 mx-6">
        {avatars.map((avatar, index) =>
          avatar !== null ? (
            <Avatar key={index} src={avatar} size={0} className="w-6 h-6 m-0.5 rounded" />
          ) : (
            <div key={index} className="w-6 h-6 m-0.5 shadow-inner rounded bg-gray-900"></div>
          )
        )}
      </div>
    </div>
  );
};

const Listing: React.FC<{ battle: Battle; onJoin: (battle: Battle) => void }> = ({
  battle,
  onJoin,
}) => {
  const owner = getOwnerDetails(battle);
  const history = useHistory();

  const ref = useRef<any>(null);

  const [round, setRound] = useState<number | null>(null);

  const timeout = useRef<NodeJS.Timeout>();

  useEffect(() => {
    const offsets = progress(battle);

    if (offsets === null) {
      return;
    }

    let advance = () => {
      if (round === battle.rounds.length) {
        return;
      }

      setRound(++offsets.current);

      timeout.current = setTimeout(() => advance(), ROUND_TIME);
    };

    timeout.current = setTimeout(() => {
      advance();
    }, ROUND_TIME - offsets.offset);

    setRound(offsets.current);

    return () => timeout.current && clearTimeout(timeout.current);
  }, [battle, round]);

  return (
    <div
      className="flex items-center justify-center w-full h-28 bg-gray-700 hover:bg-gray-400 hover:scale-101 transition-all cursor-pointer mb-3 transform rounded"
      onClick={({ target }) => {
        if (!(target instanceof Element)) {
          return;
        }

        if (ref === null || ref.current === null) {
          history.push("/battle/" + battle.id);
          return;
        }

        !ref.current.contains(target) && history.push("/battle/" + battle.id);
      }}
    >
      <Avatar src={owner?.avatar} size={1} className="w-12 h-12 mx-6 rounded" />
      <Rounds rounds={battle.rounds} current={round !== null ? round : undefined} />
      <Status players={battle.players} capacity={battle.capacity} />
      <div className="flex items-center justify-center w-56 flex-shrink-0 text-gray-10">
        {Players.count(battle.players) !== battle.capacity ? (
          <Button ref={ref} color="green" className="w-50 h-11" onClick={() => onJoin(battle)}>
            JOIN FOR <Price value={battle.cost} />
          </Button>
        ) : (
          <Button color="gray" className="w-40 h-11" href={"/battle/" + battle.id}>
            WATCH BATTLE
          </Button>
        )}
      </div>
    </div>
  );
};

const Battles = () => {
  const [livefeed] = useStore((state) => [state.livefeed]);

  const [battles, setBattles] = useState<Battle[]>([]);

  useChannel<Battle>(CHANNELS.createdBattle, {
    received(data) {
      setBattles((battles) => [data, ...battles]);
    },
  });

  useChannel<Battle>(CHANNELS.updatedBattle, {
    received(data) {
      setBattles((battles) => battles.map((battle) => (battle.id !== data.id ? battle : data)));
    },
  });

  useChannel<number>(CHANNELS.deletedBattle, {
    received(data) {
      setBattles((battles) => battles.filter((battle) => battle.id !== data));
    },
  });

  useFetch<Battle[]>("/battle", {
    onResponse(data) {
      setBattles(data);
    },
  });

  const [confirm, setConfirm] = useState<{ show: boolean; battle: Battle | null }>({
    show: false,
    battle: null,
  });

  return (
    <div className="w-full pb-10">
      <JoinConfirmModal
        show={confirm.show}
        battle={confirm.battle === null ? undefined : confirm.battle}
        close={() => setConfirm({ show: false, battle: null })}
      />
      <LiveFeed cards={livefeed} />
      <Header
        title="CASE BATTLES"
        right={
          <Button href="/battles/create" color="green" className="w-40 h-12 ml-auto">
            CREATE BATTLE
          </Button>
        }
      />
      <div className="px-3 leading-none">
        {!battles.length &&
          [...Array(5)].map((_, index) => (
            <div key={index} className="w-full h-28 mb-3 rounded">
              <Skeleton height={112} />
            </div>
          ))}
        {battles?.map((battle, index) => (
          <Listing
            key={index}
            battle={battle}
            onJoin={(battle) => setConfirm({ show: true, battle })}
          />
        ))}
      </div>
    </div>
  );
};

export default Battles;
