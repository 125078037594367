import React, { useEffect } from "react";
import { useStore } from "../store";
import { Link } from "react-router-dom";
import { UserItem } from "../types";

import Button from "../components/button";
import Avatar from "../components/Avatar";
import Price from "../components/Price";
import { InventoryItem } from "../components/item";

import { ReactComponent as Steam } from "../icons/steam.svg";
import { ReactComponent as CreditCard } from "../icons/creditcard.svg";
import { ReactComponent as Gun } from "../icons/submachine-gun.svg";
import { useAction, useFetch } from "../utils";
import { Color } from "../components/button/Button";

const HistoryLink: React.FC<{
  icon: React.ReactElement;
  name: string;
  to: string;
}> = ({ icon, name, to }) => {
  return (
    <Link className="flex items-center mr-3 hover:text-gray-25 text-gray-50" to={to}>
      {icon}
      {name}
    </Link>
  );
};

const ProfileAction: React.FC<{
  width: number;
  label: string;
  content: string | React.ReactElement;
  contentStyle?: string;
  buttonLabel: string;
  buttonColor: Color;
  buttonOnClick: () => void;
}> = ({ width, label, content, contentStyle, buttonLabel, buttonColor, buttonOnClick }) => (
  <div
    className={`flex items-center w-${width} h-9 mt-3 shadow-inner bg-gray-900 text-gray-50 rounded`}
  >
    <span className="font-semibold flex flex-shrink-0 pl-3 text-xs w-23">
      {label.toUpperCase()}:
    </span>
    <span className={`flex items-center w-full h-full text-sm ${contentStyle}`}>{content}</span>
    <Button
      className="flex-shrink-0 h-9 py-2 px-8 ml-auto"
      color={buttonColor}
      onClick={buttonOnClick}
    >
      {buttonLabel.toUpperCase()}
    </Button>
  </div>
);

const TradeurlInput: React.FC<{
  value: string;
  setValue: (tradeurl: string) => void;
}> = ({ value, setValue }) => {
  return (
    <input
      type="text"
      className="w-full h-full -ml-1.5 px-1.5 bg-opacity-0 bg-red-50"
      value={value}
      onChange={(e) => setValue(e.target.value)}
    />
  );
};

interface TradeurlResponse {
  tradeurl: string;
}

interface SellResponse {
  sold: number[];
}

const Profile = () => {
  const [me, setTradeurl] = useStore((state) => [state.me, state.setTradeurl]);

  const [displayTradeurl, setDisplayTradeurl] = React.useState("");
  const [sold, setSold] = React.useState<number[]>([]);

  const [data] = useFetch<UserItem[]>("/user/inventory");

  const [updateTradeurl] = useAction<TradeurlResponse>("/user", {
    done(data) {
      setTradeurl(data.tradeurl);
    },
  });

  const [sell] = useAction<SellResponse>("/user/inventory", {
    done(data) {
      setSold([...sold, ...data.sold]);
    },
  });

  useEffect(() => {
    me?.tradeurl && setDisplayTradeurl(me.tradeurl);
  }, [me]);

  const [inventory, totalValue] = React.useMemo(() => {
    const inventory = data?.filter(({ id }) => !sold.includes(id));
    const total = inventory?.reduce((a, b) => a + b.item.price, 0);
    return [inventory, total];
  }, [data, sold]);

  return (
    <div className="w-full pt-3 xl:pt-7 px-3 pb-7 xl:px-56">
      <div className="flex items-center w-full h-72 px-16 bg-gray-700 shadow-lg rounded">
        <Avatar
          className="h-44 w-44 mr-16 rounded"
          src={me?.avatar}
          size={2}
          name={`${me?.username}'s avatar`}
        />
        <div className="mb-0.5">
          <a
            href={`https://steamcommunity.com/profiles/${me?.steamid}`}
            target="_blank"
            rel="noreferrer"
            className="inline-flex items-center font-semibold text-gray-10 text-lg"
          >
            <Steam className="h-5 mr-1 fill-current" />
            <span>{me?.username}</span>
          </a>
          <ProfileAction
            width={80}
            label="balance"
            content={<Price value={me?.balance || 0} />}
            contentStyle="text-gray-25 text-xs"
            buttonOnClick={() => {}}
            buttonColor="red"
            buttonLabel="deposit"
          />
          <ProfileAction
            width={112}
            label="tradeurl"
            content={<TradeurlInput value={displayTradeurl} setValue={setDisplayTradeurl} />}
            contentStyle="font-normal"
            buttonOnClick={() => {
              updateTradeurl({ tradeurl: displayTradeurl.trim() });
            }}
            buttonColor="gray"
            buttonLabel="save"
          />
          <div className="flex items-center mt-3 text-xs">
            <HistoryLink
              icon={<CreditCard className="fill-current w-3 h-3 mr-1" />}
              name="PAYMENT HISTORY"
              to="/profile/payments"
            />
            <HistoryLink
              icon={<Gun className="fill-current w-3 h-3 mr-1" />}
              name="WITHDRAW HISTORY"
              to="/profile/withdrawal"
            />
          </div>
        </div>
      </div>
      <div className="flex items-center h-20">
        <span className="font-semibold text-gray-25 text-xl">INVENTORY</span>
        <Button
          className="h-9 py-2 px-8 ml-6"
          color="gray"
          onClick={() => {
            sell({ sell: inventory?.map(({ id }) => id) || [] });
          }}
        >
          SELL ALL (<Price value={totalValue || 0} />)
        </Button>
        <Button className="h-9 py-2 px-8 ml-6" color="gray">
          WITHDRAW ITEMS
        </Button>
      </div>
      <div className="grid gap-3 grid-cols-items">
        {inventory?.map((item) => (
          <InventoryItem
            key={item.id}
            item={item}
            className="bg-gray-700 shadow-md"
            onSell={(id) => {
              sell({ sell: [id] });
            }}
          />
        ))}
      </div>
    </div>
  );
};

export default Profile;
