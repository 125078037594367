import React from "react";

const Subscriber = () => {
  /**
   * Connect to websocket server and fetch updates to balance,
   * livefeed, battles, online players, cases opened.
   */

  return <></>;
};

export default Subscriber;
