import React from "react";

const Header: React.FC<{ title: string; left?: JSX.Element; right?: JSX.Element }> = ({
  title,
  left,
  right,
}) => (
  <div className="flex justify-center items-center w-full my-3 px-5 text-gray-25">
    {left}
    <span className="absolute text-xl font-semibold">{title.toUpperCase()}</span>
    {right}
  </div>
);

export default Header;
