import React from 'react';

const TextLink: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return (
    <a className="font-semibold text-tiny mr-4 text-gray-50 hover:text-red transition-colors" href="/">
      {children}
    </a>
  );
}

export default TextLink;