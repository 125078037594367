import React from "react";
import { useAction } from "../../utils";
import { useStore } from "../../store";
import { User } from "../../types";

import TextLink from "../TextLink";
import GameLink from "./GameLink";
import Level from "../Level";
import Login from "./Login";

import { ReactComponent as Box } from "../../icons/box.svg";
import { ReactComponent as Sword } from "../../icons/sword.svg";
import { ReactComponent as Logout } from "../../icons/log-out.svg";

const ExtraUserDetails: React.FC<{ user: User | null }> = ({ user }) => {
  const [invalidate] = useStore((state) => [state.logout]);

  const [logout] = useAction<string>("/steam/logout", {
    done: () => invalidate(),
  });

  if (!user) {
    return <></>;
  }

  return (
    <>
      <Level className="ml-auto" level={3} progress={0.32} />
      <button className="text-gray-50 hover:text-gray-25" onClick={() => logout()}>
        <Logout className="fill-current w-5 h-5" />
      </button>
    </>
  );
};

const Navigation: React.FC<{ me: User | null }> = ({ me }) => {
  return (
    <nav className="fixed flex w-full h-20 pl-4 bg-gray-700 z-20">
      <div className="h-full p-2">
        <img className="h-full" src="/BAMA.png" alt=""></img>
      </div>
      <div className="flex flex-col flex-1 h-full pl-3">
        <div className="flex items-center w-full h-7 px-3 bg-gray-800 shadow-inner">
          <TextLink>Fairness</TextLink>
          <TextLink>Referrals</TextLink>
          <TextLink>Help</TextLink>
          <TextLink>Support</TextLink>
          <span className="text-red text-md">{JSON.stringify(process.env)}</span>
          <ExtraUserDetails user={me} />
        </div>
        <div className="flex flex-1 items-center w-full">
          <GameLink to="/">
            <Box className="-ml-1 mr-1 h-5" />
            UNBOXING
          </GameLink>
          <GameLink to="/battles">
            <Sword className="fill-current -ml-1 mr-1 h-5" />
            CASE BATTLES
          </GameLink>
          <Login user={me} />
        </div>
      </div>
    </nav>
  );
};

export default Navigation;
