import React, { CSSProperties } from "react";
import { Style } from "../../utils";

type FlexDirection = "vertical" | "horizontal";

const directions = {
  vertical: "flex-col",
  horizontal: "",
};

const Box: React.FC<{ className?: string; children?: React.ReactNode; direction?: FlexDirection }> =
  ({ className, children, direction = "horizontal" }) => (
    <div
      className={Style(
        "flex",
        directions[direction],
        className,
        "items-center p-3 gap-6 bg-gray-400 rounded"
      ).toString()}
    >
      {children}
    </div>
  );

type TextSize = "xs" | "sm" | "base" | "lg";

const text_sizes = {
  xs: "text-xs",
  sm: "text-sm",
  base: "text-base",
  lg: "text-lg",
};

const Value: React.FC<{
  children?: React.ReactNode;
  className?: string;
  label?: string;
  textSize?: TextSize;
}> = ({ textSize = "sm", children, label, className }) => {
  return label ? (
    <div className={Style("text-gray-25", text_sizes[textSize], className).toString()}>
      <span className="mr-1 uppercase">{label}</span>
      <span className="font-semibold text-red">{children}</span>
    </div>
  ) : (
    <span className="mr-1 uppercase">{label}</span>
  );
};

type Direction = "top" | "right" | "bottom" | "left";

const Triangle: React.FC<{
  className?: string;
  style?: CSSProperties;
  w?: number;
  h?: number;
  direction?: Direction;
  color?: string;
}> = ({ className, style, w = 20, h = 20, direction = "right", color = "#44a6e8" }) => {
  const points = {
    top: [`${w / 2},0`, `0,${h}`, `${w},${h}`],
    right: [`0,0`, `0,${h}`, `${w},${h / 2}`],
    bottom: [`0,0`, `${w},0`, `${w / 2},${h}`],
    left: [`${w},0`, `${w},${h}`, `0,${h / 2}`],
  };

  return (
    <svg className={className} style={style} width={w} height={h}>
      <polygon points={points[direction].join(" ")} fill={color} />
      Sorry, your browser does not support inline SVG.
    </svg>
  );
};

export { Value, Box, Triangle };
