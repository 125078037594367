interface Style {
  if: (predicate: boolean, style: string) => Style;
  toString: () => string;
}

const Style = (...xs: (string | boolean | undefined)[]) => {
  xs = xs.filter((x) => !!x);

  return {
    if(predicate: boolean | undefined, style: string, other?: string) {
      !!predicate && xs.push(style);
      !predicate && !!other && xs.push(other);
      return this;
    },
    toString() {
      return xs.join(" ");
    },
  };
};

export default Style;
