import React from "react";
import ItemImage from "./ItemImage";
import Price from "../Price";

import { UserItem as InventoryItemProps } from "../../types";

const InventoryItem: React.FC<{
  item: InventoryItemProps;
  className?: string;
  onSell?: (id: number) => void;
}> = ({
  item: {
    item: { name, image, price },
    id,
  },
  className,
  onSell,
}) => (
  <div
    className={
      "relative h-52 px-3 pt-4 bg-case text-gray-25 text-xs font-semibold rounded " + className
    }
  >
    <ItemImage className="w-24 h-24 mx-auto mb-3" name={name} image={image} />
    <span className="block w-full text-center text-xs">{name.toUpperCase()}</span>
    <div className="group absolute w-full left-0 bottom-3 px-3">
      <div
        className="relative flex items-center h-8 ml-auto cursor-pointer rounded bg-gray-900 shadow-inner"
        onClick={() => onSell && onSell(id)}
      >
        <span className="group-hover:opacity-0 ml-2 duration-100">
          <Price value={price} />
        </span>
        <div className="absolute flex items-center justify-center right-0 group-hover:w-full w-14 h-full px-3 ml-auto bg-red-500 text-tiny rounded duration-200">
          SELL
        </div>
      </div>
    </div>
  </div>
);

export default InventoryItem;
