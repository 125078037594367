import React from "react";

import LiveFeed from "../components/livefeed";
import { Grid } from "../components/case";

import { useFetch } from "../utils";
import { Case } from "../types";

import { useStore } from "../store";

/* Temporary until we finish the backend for the giveaways. */
// const giveaways: [Giveaway, Giveaway, Giveaway] = [
//   {
//     id: 1,
//     title: "Hourly Giveaway",
//     deposit: 500,
//     round: {
//       startedAt: 1606760597717,
//       finishedAt: 1606764197717,
//     },
//     joined: false,
//   },
//   {
//     id: 2,
//     title: "Daily Giveaway",
//     deposit: 1000,
//     round: {
//       startedAt: 1606760597717,
//       finishedAt: 1606764197717,
//     },
//     joined: false,
//   },
//   {
//     id: 3,
//     title: "Weekly Giveaway",
//     deposit: 2000,
//     round: {
//       startedAt: 1606760597717,
//       finishedAt: 1606764197717,
//     },
//     joined: false,
//   },
// ];

const Cases = () => {
  const [livefeed] = useStore((state) => [state.livefeed]);

  const [cases] = useFetch<Case[]>("/case");

  return (
    <div className="w-full pb-10">
      <LiveFeed cards={livefeed} />
      {/* <Giveaways giveaways={giveaways} /> */}
      <Grid cases={cases || []} />
    </div>
  );
};

export default Cases;
