import create, { SetState } from "zustand";
import { User, LivefeedCard } from "./types";

interface Me {
  me: User | null;
  logout: () => void;
  authenticate: (user: User) => void;
  setTradeurl: (tradeurl: string) => void;
  setBalance: (balance: number) => void;
}

interface Livefeed {
  livefeed: { card: LivefeedCard; animate: boolean }[];
  livefeedInit: (cards: LivefeedCard[]) => void;
  livefeedAdd: (card: LivefeedCard) => void;
}

interface Store extends Livefeed, Me {}

const me = (set: SetState<Store>) => ({
  me: null,

  logout: () => set(() => ({ me: null })),
  authenticate: (user: User) => set(() => ({ me: user })),

  setTradeurl: (tradeurl: string) =>
    set((state) => (state.me ? { me: { ...state.me, tradeurl } } : { me: null })),

  setBalance: (balance: number) =>
    set((state) => (state.me ? { me: { ...state.me, balance } } : { me: null })),
});

const livefeed = (set: SetState<Store>) => ({
  livefeed: [],

  livefeedInit: (cards: LivefeedCard[]) =>
    set({ livefeed: cards.map((card) => ({ card, animate: false })) }),

  livefeedAdd: (card: LivefeedCard) =>
    set((state) => ({ livefeed: [{ card, animate: true }, ...state.livefeed.slice(0, 24)] })),
});

const useStore = create<Store>((set) => ({
  ...me(set),
  ...livefeed(set),
}));

export { useStore };

// type State = {
//   me?: User;
//   cards: { card: FeedCard; animate: boolean }[];
//   initialCards: (cards: FeedCard[]) => void;
//   pushCard: (card: FeedCard) => void;
//   login: (user: User) => void;
//   setBalance: (balance: number) => void;
//   setTradeurl: (tradeurl: string) => void;
// };

// const useStore = create<State>((set) => ({
//   cards: [],
//   initialCards: (cards) =>
//     set(() => ({ cards: cards.map((card) => ({ card, animate: false })) })),
//   pushCard: (card) =>
//     set((state) => ({
//       cards: [{ card, animate: true }, ...state.cards.slice(0, 24)],
//     })),
//   login: (user) => set(() => ({ me: user })),
//   setBalance: (balance) =>
//     set((state) => {
//       return state.me ? { me: { ...state.me, balance } } : {};
//     }),
//   setTradeurl: (tradeurl) =>
//     set((state) => (state.me ? { me: { ...state.me, tradeurl } } : {})),
// }));

// export { useStore };
