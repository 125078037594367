import React, { useState } from "react";
import ReactDOM from "react-dom";

const modals = document.getElementById("modals");

const Modal: React.FC<{
  show?: boolean;
  className?: string;
  children: React.ReactNode;
  onExit?: () => void;
}> = ({ className, show, children, onExit = () => {} }) => {
  const [el] = useState<HTMLDivElement>(() => {
    const el = document.createElement("div");
    const classes = className + " fixed w-full h-screen top-0 bg-opacity-50 bg-black z-30";
    el.classList.add(...classes.split(" ").filter((s) => s !== ""));
    el.addEventListener("click", (e) => {
      e.target === el && onExit();
    });

    return el;
  });

  React.useLayoutEffect(() => {
    if (!show) {
      return;
    }

    modals?.appendChild(el);
    return () => {
      modals?.removeChild(el);
    };
  }, [el, show]);

  return ReactDOM.createPortal(children, el);
};

const Uppercase: React.FC<{ children?: React.ReactNode }> = ({ children }) => (
  <>
    {React.Children.map(children, (child) =>
      typeof child === "string" ? child.toUpperCase() : child
    )}
  </>
);

const ModalWithHeading: React.FC<{
  children?: React.ReactNode;
  className?: string;
  show: boolean;
  onExit?: () => void;
  heading: string | JSX.Element;
  subheading?: string | JSX.Element;
}> = ({ children, className, show, onExit, heading, subheading }) => (
  <Modal show={show} onExit={onExit}>
    <div className={`relative ${className} bg-gray-700 rounded`}>
      <div className="flex flex-col py-4">
        <span className="text-xs font-semibold text-gray-25">
          <Uppercase>{heading}</Uppercase>
        </span>
        <span className="text-tiny text-gray-50">
          <Uppercase>{subheading}</Uppercase>
        </span>
      </div>
      {children}
    </div>
  </Modal>
);

export { Modal, ModalWithHeading };
