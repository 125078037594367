import React, { useState } from "react";
import { Case } from "../../types";

import CaseImage from "../CaseImage";
import Price from "../Price";
import Button from "../button";
import { Box, Value } from "../layout";

const SectionSelect: React.FC<{
  onSectionChange: (section: number) => void;
  sections: JSX.Element[];
}> = ({ onSectionChange, sections }) => {
  const [section, setSection] = useState(0);

  const changeSlot = (index: number) => (_: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    onSectionChange(index);
    setSection(index);
  };

  return (
    <div className="flex items-center px-4 py-3 text-gray-25">
      {sections.map((name, index) => (
        <button
          key={index}
          className={`flex items-center mr-4 font-semibold ${
            index === section ? "border-b-2 text-red" : "hover:text-gray-10"
          } border-red-500`}
          onClick={changeSlot(index)}
        >
          {name}
        </button>
      ))}
    </div>
  );
};

const Display: React.FC<{ box: Case }> = ({ box }) => {
  return (
    <Box className="h-72" direction="vertical">
      <CaseImage className="w-40" name={box.name} src={box.image} />
      <Value label={box.fullname}>
        <Price value={box.price} />
      </Value>
      <Button href={"/case/" + box.name} color="green" className="w-full h-10">
        OPEN CASE
      </Button>
    </Box>
  );
};

const CaseGrid: React.FC<{ cases: Case[] }> = ({ cases }) => {
  return (
    <>
      <SectionSelect
        onSectionChange={(_) => {}}
        sections={[<>FEATURED</>, <>NEW</>, <>50/50</>, <>10%</>, <>5%</>, <>WEEKLY SPECIAL</>]}
      />
      <div className="grid gap-7 grid-cols-cases px-3">
        {cases?.map((value, index) => (
          <Display key={index} box={value} />
        ))}
      </div>
    </>
  );
};

export default CaseGrid;
